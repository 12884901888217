<mat-toolbar class="banner" role="banner" color="primary">
  <div class="container">
    <div class="row">
      <div
        class="col-6 col-md-10 d-flex flex-wrap align-items-center zone-logo-title"
      >
        <a class="logo" tabindex="5" routerLink="">
          <img id="header-logo" alt="Logo" src="{{ headerLogo }}" />
        </a>
        <img
          id="header-logo-print"
          alt="Logo"
          src="{{ headerLogoPrint }}"
          width="199"
          height="60"
        />
        <p lang="fr" id="title-desktop" class="zone-title d-none d-md-block">
          {{ 'header.title' | translate }}
        </p>
      </div>
      <div class="col-6 col-md-2 d-flex justify-content-end zone-links">
        <ul class="nav flex-column">
          <!--<li><button class="buttonLanguage" disabled (click)="changeLanguage()">{{'header.language'}}</button></li>-->
          <li>
            <a
              rel="noopener noreferrer"
              alt="('header.contactUsUrl' | translate)"
              href="{{ 'header.contactUsUrl' | translate }}"
              target="_blank"
              >{{ 'header.contactUs' | translate }}</a
            >
          </li>
        </ul>
      </div>
      <p lang="fr" id="title-mobile" class="zone-title d-md-none col-12">
        <a>{{ 'header.title' | translate }}</a>
      </p>
    </div>
  </div>
</mat-toolbar>
