<igo-spinner igoSpinnerActivity></igo-spinner>

<igo-auth-form *ngIf="authConfig && authConfig.url"></igo-auth-form>

<app-header *ngIf="hasHeader" #header></app-header>

<app-portal
  [ngClass]="
    hasHeader
      ? hasFooter
        ? 'portal-with-header-footer'
        : 'portal-with-header'
      : hasFooter
      ? 'portal-with-footer'
      : 'portal'
  "
  igoStopDropPropagation
></app-portal>

<app-footer *ngIf="hasFooter"></app-footer>
