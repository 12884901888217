<div>
  <div class="app-expansion-panel-container">
    <igo-backdrop [shown]="backdropShown" (click)="onBackdropClick()">
    </igo-backdrop>

    <app-expansion-panel-header [(expanded)]="expanded">
      <ng-content select="[expansionPanelHeader]"></ng-content>
    </app-expansion-panel-header>

    <div
      class="app-expansion-panel-content mat-elevation-z4"
      [@showContent]="expanded"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
