import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfigService, LanguageService } from '@igo2/core';

import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { version } from 'src/environments/version';

import { WelcomeWindowService } from './welcome-window.service';

@Component({
  selector: 'app-welcome-window',
  templateUrl: './welcome-window.component.html',
  styleUrls: ['./welcome-window.component.scss']
})
export class WelcomeWindowComponent implements OnInit, OnDestroy {
  // isVisible = true;
  showAgain = false;
  public discoverTitleInLocale$: Observable<string>;
  private title$$: Subscription;
  public html$: BehaviorSubject<string> = new BehaviorSubject(undefined);

  constructor(
    public dialog: MatDialog,
    private welcomeWindowService: WelcomeWindowService,
    private configService: ConfigService,
    protected languageService: LanguageService
  ) {
    this.discoverTitleInLocale$ = of(
      this.configService.getConfig(
        'welcomeWindow.discoverTitleInLocale',
        this.configService.getConfig('title')
      )
    );
  }

  ngOnInit(): void {
    this.computeHtml();
  }

  closeWelcomeWindow() {
    this.dialog.closeAll();
  }

  private computeHtml() {
    this.title$$ = this.languageService.translate
      .get(this.configService.getConfig('title', ''))
      .pipe(
        map((title) => {
          return this.languageService.translate.instant('welcomeWindow.html', {
            title,
            description: this.configService.getConfig('description', ''),
            version: version.app,
            releaseDate: new Intl.DateTimeFormat('fr-CA').format(
              new Date(version.releaseDate)
            )
          });
        })
      )
      .subscribe((r) => this.html$.next(r));

    return this.html$;
  }

  setShowAgain() {
    this.welcomeWindowService.showAgain = this.showAgain;
  }

  ngOnDestroy(): void {
    if (this.title$$) {
      this.title$$.unsubscribe();
    }
  }
}
