<div class="welcome-window" *ngIf="html$ | async">
  <mat-toolbar color="primary">
    <h4>{{ 'welcomeWindow.title' | translate }}</h4>
    <span class="spacer"></span>

    <label class="mat-body-2">
      <input
        id="doNotShowCheck"
        type="checkbox"
        [(ngModel)]="showAgain"
        (click)="setShowAgain()"
      />
      {{ 'welcomeWindow.notShowCheck' | translate }}
    </label>
  </mat-toolbar>

  <div mat-dialog-content class="text">
    <igo-custom-html [html]="html$ | async"></igo-custom-html>
  </div>

  <p></p>
  <div class="button-container">
    <igo-interactive-tour
      id="tour-button"
      styleButton="raised"
      [discoverTitleInLocale$]="discoverTitleInLocale$"
      (click)="closeWelcomeWindow()"
    >
    </igo-interactive-tour>

    <button
      mat-raised-button
      class="button-close"
      color="primary"
      mat-dialog-close
    >
      {{ 'welcomeWindow.closeButton' | translate }}
    </button>
  </div>
</div>
