<ng-container *ngFor="let element of mapOverlay; let i = index">
  <div
    class="mapOverlayDefault"
    [ngClass]="element.cssClass"
    [style.position]="element.fixed ? 'fixed' : 'absolute'"
    [style.marginLeft]="element.marginLeft"
    [style.marginRight]="element.marginRight"
    [style.marginTop]="element.marginTop"
    [style.marginBottom]="element.marginBottom"
    [style.fontSize]="element.fontSize"
  >
    <a
      *ngIf="element.link; else templateNoLink"
      target="_blank"
      [href]="element.link"
    >
      {{ element.text }}
      <ng-container *ngIf="element.imgSrc">
        <img
          [src]="element.imgSrc"
          [alt]="element.alt"
          [ngStyle]="{ width: element.imgSize }"
        />
      </ng-container>
    </a>

    <ng-template #templateNoLink>
      {{ element.text }}
      <ng-container *ngIf="element.imgSrc">
        <img
          [src]="element.imgSrc"
          [alt]="element.alt"
          [ngStyle]="{ width: element.imgSize }"
        />
      </ng-container>
    </ng-template>
  </div>
</ng-container>
