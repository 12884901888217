import { environment as prodEnvironment } from './environment.prod';
import { AppEnvironmentOptions } from './environnement.interface';

const environment: AppEnvironmentOptions = {
  ...prodEnvironment
};

if (environment.igo.monitoring) {
  environment.igo.monitoring.environment = 'Development';
  environment.igo.monitoring.logErrors = true;
}

export { environment };
